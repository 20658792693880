import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import MapView, { Marker } from 'react-native-maps';

export default function App() {
  return (
    <View style={styles.container}>
      <MapView
          provider="google"
          googleMapsApiKey="AIzaSyCUNyOnYYrYEPci3c8BG9xE2dioEL0sMPU"

      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //backgroundColor: '#fff',
   //alignItems: 'center',
    //justifyContent: 'center',
  },
});
